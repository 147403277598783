import React from 'react';

import SiteWrapper from '../components/site-wrapper';
import Section from '../primitives/section';
import Layout from '../primitives/layout';

export default function CookiePolicy() {
  return (
    <SiteWrapper>
      <Layout>
        <Section width="medium">
          <h1>Cookie Policy</h1>
          <p>
            <strong>1. Introduction</strong>
          </p>
          <p>
            Effective as of January 1, 2019, LifeLoop, LLC ("LifeLoop", "We",
            "Our", "Us") have updated our Cookie Policy. This Cookie Policy
            explains that we believe in being open and clear about how we use
            your information. Please also refer to our Privacy Policy, into
            which this Cookie Policy is incorporated by reference, which sets
            forth our policy with respect to the collection, use, disclosure,
            transfer, and storage of information, including personal data, that
            is collected from you. Nothing in this Cookie Policy shall be deemed
            to modify, waive, amend, or rescind any other term of the Privacy
            Policy.
          </p>
          <p>
            <strong>2. Consent</strong>
          </p>
          <p>
            By visiting www.lifeloop.com, or any other website owned or operated
            by LifeLoop (collectively "Websites"), you are accepting and
            consenting to the practices described in this Cookie Policy.
          </p>
          <p>
            <strong>3. Cookies</strong>
          </p>
          <p>
            We use cookies for content personalization, analytics/performance,
            functional purposes, as well as the ability to access secure areas
            of our site. Users can control the use of cookies at the individual
            browser level. If you reject cookies, you may still use our site,
            but your ability to use some features or areas of our site may be
            limited.
          </p>
          <p>
            <strong>4. What is a Cookie?</strong>
          </p>
          <p>
            A cookie is a small text file that is placed on your hard drive by a
            web page server. Cookies contain information that can later be read
            by a web server in the domain that issued the cookie to you. Some of
            the cookies will only be used if you use certain features or select
            certain preferences, and some cookies will always be used. You can
            find out more about each cookie by viewing our current cookie list
            below. We update this list biannually, so there may be additional
            cookies that are not yet listed. Web beacons, tags and scripts may
            be used in the Websites or in emails to help us to deliver cookies,
            count visits, understand usage and campaign effectiveness and
            determine whether an email has been opened and acted upon. We may
            receive reports based on the use of these technologies by our
            service/analytics providers on an individual and aggregated basis.
          </p>
          <p>
            <strong>5. Why does LifeLoop use Cookies?</strong>
          </p>
          <p>
            When you visit our Websites, we may place a number of cookies in
            your browser. These are known as Essential Cookies and are required
            to enable to hold session information as you navigate from page to
            page within the website. For example, we use cookies on our Websites
            to understand visitor and user preferences, improve their
            experience, and track and analyze usage, navigational and other
            statistical information. You can control the use of cookies at the
            individual browser level. If you elect not to activate the cookie or
            to later disable cookies, you may still visit our Websites, but your
            ability to use some features or areas of the Websites may be
            limited.
          </p>
          <p>
            Many jurisdictions require or recommend that website operators
            inform users/visitors as to the nature of cookies they utilize and,
            in certain circumstances, obtain the consent of their users to the
            placement of certain cookies.
          </p>
          <p>
            <strong>6. Cookies Subject to Change</strong>
          </p>
          <p>
            The content of this Cookie Policy is for your general information
            and use only. These cookies are subject to change without notice.
            You acknowledge that this information may contain inaccuracies or
            errors and is subject to change and we expressly exclude liability
            for any such inaccuracies or errors to the fullest extent permitted
            by law.
          </p>
          <p>
            <strong>7. Updating This Policy</strong>
          </p>
          <p>
            If there are any material changes to this Cookie Policy, you will be
            notified by the posting of a prominent notice on our Websites prior
            to the change becoming effective. We encourage you to periodically
            review this page for the latest information on the Cookie Policy.
            Your continued use of the Websites constitutes your agreement to be
            bound by such changes to this Cookie Policy. Your only remedy, if
            you do not accept the terms of this Cookie Policy, is to discontinue
            use of and access to the Websites.
          </p>
        </Section>
      </Layout>
    </SiteWrapper>
  );
}
